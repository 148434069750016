.errorPage {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  height: 100vh;
  justify-content: center;
  color: black;
}

.errorPage h1 {
  font-size: xxx-large;
}
